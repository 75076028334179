import React, { createContext, useEffect, useReducer } from 'react';
import { axios } from 'src/lib/axios';
import { logger } from 'src/lib/logger';
import { RestResponse } from 'src/types';
import {
  setAuthToken,
  setSentryUser,
  unSetSentryUser,
  removeAuthToken,
} from 'src/app/auth/use-auth';

import { getCookie } from 'src/lib/cookies';
import { COOKIES_AUTH_TOKEN } from 'src/constants/cookies';
import is from 'is_js';
import Router from 'next/router';
import { getLocalStorage } from 'src/heplers/localStorage';

interface Email {
  email: string;
}

interface SignInRequest extends Email {
  password: string;
}
interface SigUpRequest extends SignInRequest {
  username: string;
}
interface SignInResponse {
  data: UserInfoResponse;
  token: string;
}
interface UserInfoResponse extends Email {
  avatar: string;
  email: string;
  firstName: string;
  id: number;
  isActivated: boolean;
  lastName: string;
  username: string;
  profileImgURL?: string;
  userRole?: string;
  isLanded?: boolean;
  about?: string;
}

export interface UserContextType {
  user: UserInfoResponse;
  isLogin: boolean;
  signIn: (data: SignInRequest) => void;
  signUp: (ata: SigUpRequest) => void;
  logout: () => void;
  forgetPassword: (data: Email) => void;
}

export const UserContext = createContext<UserContextType | undefined>(
  undefined
);
type Props = {
  children: React.ReactNode;
};

export function userReducer(state, action) {
  let userInfo = {};
  switch (action.type) {
    case 'FETCH_USER':
      userInfo = setUserInfo(action.data);
      return { isLogin: true, user: userInfo };
    // eslint-disable-next-line no-fallthrough
    case 'LOGOUT':
      return { isLogin: false, user: {} as UserInfoResponse };

    // eslint-disable-next-line no-fallthrough
    default:
      return state;
  }
}
const setUserInfo = (userInfo) => {
  return {
    avatar: userInfo.profileImgURL,
    email: userInfo.email,
    firstName: userInfo.firstName,
    id: userInfo.id,
    isActivated: userInfo.isActivated,
    lastName: userInfo.lastName,
    username: userInfo.username,
  };
};
export const initState = {
  isLogin: false,
  user: {} as UserInfoResponse,
};

const UserProvider = ({ children }: Props) => {
  const [state, userDispatch] = useReducer(userReducer, initState);

  const authToken = getCookie(COOKIES_AUTH_TOKEN);
  const routeLink = getLocalStorage('routeLink') || '/';

  useEffect(() => {
    if (authToken && authToken !== '' && is.empty(state.user)) {
      const fetchUser = async () => {
        try {
          const response = await axios
            .get<RestResponse<UserInfoResponse>>(`/user/userinfo`)
            .then((res) => res.data);

          if (response.code === 1000) {
            userDispatch({ type: 'FETCH_USER', data: response.data });
          }
        } catch (error) {
          logger.error(error);
          // Router.push({ query: { ...Router.query, page: 'signIn' } });
        }
      };
      fetchUser();
    }
  }, [authToken, state.user]);

  const signIn = async (data: SignInRequest) => {
    try {
      const response = await axios.post<RestResponse<SignInResponse>>(
        '/user/login',
        data
      );
      if (response.data.code === 1000) {
        const { data: resData } = response.data;
        if (resData.data.isActivated) {
          setAuthToken(resData.token);
          setSentryUser({
            id: resData.data.id,
            username: resData.data.username,
            firstName: resData.data.firstName,
            lastName: resData.data.lastName,
            email: resData.data.email,
            userRole: resData.data.userRole,
          });
          userDispatch({ type: 'FETCH_USER', data: resData.data });

          if (Router.query.page !== 'signIn') {
            if (!resData.data.isLanded) {
              await axios.post<RestResponse<any>>(`/user/landed`);
              Router.push(`/${resData.data.username}`);
            } else {
              Router.push(`${routeLink}`);
            }
          }
        } else {
          return {
            status: 401,
            data: { code: response.data.code, msg: 'Account not activated' },
          };
        }
      }
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      logger.error(error);
      return {
        status: error.response.status,
        data: error.response.data,
      };
    }
  };

  // NOTE : forgetPassword still use old fn
  const forgetPassword = (data: Email) => {
    return axios.post<RestResponse<unknown>>('/user/forgotpw', data);
  };
  // NOTE : signUp still use old fn
  const signUp = (data: SigUpRequest) => {
    return axios
      .post<
        RestResponse<{
          token: string;
        }>
      >('/user/register', data)
      .then((res) => res.data);
  };

  const logout = () => {
    userDispatch({ type: 'LOGOUT' });
    unSetSentryUser();
    removeAuthToken();
    axios.post('/user/logout').catch(logger.error);
    window.location.replace('/');
  };
  return (
    <UserContext.Provider
      value={{ ...state, signIn, signUp, logout, forgetPassword }}
    >
      {children}
    </UserContext.Provider>
  );
};
export default UserProvider;

export const UserConsumer = UserContext.Consumer;
