import { createContext, useEffect, useState, useContext } from 'react';
import { fetchNotification, fetchNotificationTop } from 'src/app/auth/service';
import { useRouter } from 'next/router';
import { UserContext, UserContextType } from '../contexts/UserContext';

export const NotificationContext = createContext([]);

export const NotificationContextProvider = (props) => {
  const [value, setValue] = useState({});
  const { isLogin } = useContext(UserContext) as UserContextType;
  const router = useRouter();
  function cardFilter(typeValue, data) {
    const newnotif = data?.filter(function filterNotif(notifData) {
      return notifData.objectType === typeValue;
    });
    return newnotif;
  }

  useEffect(() => {
    async function getNotification() {
      try {
        let response;
        if (router.pathname === '/[username]/notification') {
          response = await fetchNotification();
        } else {
          response = await fetchNotificationTop();
        }

        if (response.code === 200 || response.code === 0) {
          const projectLength = cardFilter('Project', response.data.data)
            .length;
          const postLength = cardFilter('Post', response.data.data).length;
          setValue({
            project: projectLength,
            post: postLength,
            notifCount: response?.data.totalUnreadCount,
            data: response?.data?.data.map((notifData) => notifData),
          });
        }
        return null;
      } catch (err) {
        return null;
      }
    }
    if (Object.keys(value).length === 0 && isLogin) {
      getNotification();
    }
  }, [value, isLogin]);

  return (
    <NotificationContext.Provider value={[value, setValue]}>
      {props.children}
    </NotificationContext.Provider>
  );
};
