export enum CacheKey {
  article = 'article',
  user = 'user',
  profile = 'profile',
  featureFlag = 'feature-flag',
  reppl = 'reppl',
  repplPublishable = 'reppl-publishable',
  projectList = 'project-list',
  creationList = 'creation-list',
  episodeList = 'episode-list',
  unsplash = 'unsplash',
  label = 'label',
  labelList = 'label-list',
  collectionList = 'collection-list',
  creationLabel = 'creation-label',
  curatableList = 'curatable-list',
  twitterTweet = 'twitter-tweet',
  showMeReppl = 'show-me-reppl',
  showMeStudio = 'show-me-studio',
  projectBio = 'project-bio',
  projects = 'projects',
  lookupUsers = 'lookup-users',
  invitationList = 'invitation-list',
  userSubmission = 'user-submission',
  repplSubmission = 'reppl-submission',
}
