import { axios } from 'src/lib/axios';
import { logger } from 'src/lib/logger';
import { RestResponse } from 'src/types';
import { UserInfoResponse } from './authenticate';

interface ForgetPasswordRequest {
  email: string;
}

export function forgetPassword(data: ForgetPasswordRequest) {
  return axios.post<RestResponse<unknown>>('/user/forgotpw', data);
}

export interface SignInRequest {
  email: string;
  password: string;
}

export interface SignInResponse {
  data: UserInfoResponse;
  token: string;
}

interface SigUpRequest {
  email: string;
  password: string;
  username: string;
}

export function signIn(data: SignInRequest) {
  return axios
    .post<RestResponse<SignInResponse>>('/user/login', data)
    .then((res) => res.data);
}

export function signUp(data: SigUpRequest) {
  return axios
    .post<
      RestResponse<{
        token: string;
      }>
    >('/user/register', data)
    .then((res) => res.data)
    .catch((err) => err.response.data);
}

export function codeVerify(data: any) {
  return axios
    .post<RestResponse<any>>('/user/invitation/verify', data)
    .then((res) => res.data)
    .catch((err) => err.response.data);
}

export function codeConsume(data: any) {
  return axios.put<RestResponse<any>>('/user/invitation', data);
}

export function resendEmail(data: any) {
  return axios.get<RestResponse<any>>(
    `/resend_auth?email=${encodeURIComponent(data.email)}`
  );
}

export function changeEmail(data: any) {
  return axios
    .post<RestResponse<any>>('user/re-enter', data)
    .then((res) => res.data)
    .catch((err) => err.response.data);
}

export function activateUser(data: any) {
  return axios
    .post<RestResponse<any>>('/user/activate', data)
    .then((res) => res.data)
    .catch((err) => err.response.data);
}

export function logout() {
  return axios
    .post('/user/logout')
    .then((res) => res.data)
    .catch(logger.error);
}

export function fetchNotification() {
  return axios
    .get<RestResponse<any[]>>(`/user/notification`)
    .then((res) => res.data);
}

export function fetchNotificationTop() {
  return axios
    .get<RestResponse<any[]>>(`/user/notification/top`)
    .then((res) => res.data);
}

export function updateNotification(id: number) {
  const data = { read: true };
  return axios
    .put<RestResponse>(`/user/notification/${id}/read`, data)
    .then((res) => res.data);
}
