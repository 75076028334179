import * as React from 'react';
import { Svg } from '../svg';

function SvgBackToTheFutureDark({ title, titleId, ...props }, svgRef) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 344 252"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M41.02 195.622l-26.77-28.729 59.782-18.465s1.505 5.496 4.384 13.086c2.879 7.589 5.234 12.562 5.234 12.562l-42.63 21.546z"
        fill="url(#back-to-the-future-dark_svg__paint0_linear_5760_225999)"
      />
      <path
        d="M21.599 138.087L9.567 95.753l55.698.4s.267 8.535.95 14.602c.684 6.068 2.118 14.908 2.118 14.908L21.6 138.087z"
        fill="url(#back-to-the-future-dark_svg__paint1_linear_5760_225999)"
      />
      <path
        opacity={0.4}
        d="M190.196 110.262c8.05 30.044 8.699 58.984 3.277 81.473-5.431 22.52-16.891 38.384-32.905 42.675-16.013 4.291-33.87-3.718-49.833-20.506-15.941-16.764-29.849-42.152-37.899-72.196-8.05-30.043-8.7-58.983-3.277-81.472 5.43-22.52 16.89-38.385 32.905-42.676 16.013-4.29 33.87 3.718 49.833 20.506 15.941 16.765 29.849 42.152 37.899 72.196z"
        fill="url(#back-to-the-future-dark_svg__paint2_radial_5760_225999)"
        stroke="#09F"
        strokeWidth={1.5}
      />
      <path
        d="M112.998 116.411l-3.367-23.907h24.133s.141 3.777.52 8.016a113.04 113.04 0 001.072 8.217l-22.358 7.674z"
        fill="url(#back-to-the-future-dark_svg__paint3_linear_5760_225999)"
      />
      <path
        d="M133.438 157.706l-10.272-21.336 17.582-4.484s1.444 4.364 3.2 8.977c1.756 4.613 3.816 8.968 3.816 8.968l-14.326 7.875z"
        fill="url(#back-to-the-future-dark_svg__paint4_linear_5760_225999)"
      />
      <path
        opacity={0.6}
        d="M227.652 101.208c6.04 22.542 6.495 44.242 2.395 61.085-4.11 16.885-12.719 28.644-24.604 31.829-11.885 3.184-25.22-2.695-37.222-15.263-11.972-12.536-22.428-31.557-28.468-54.099-6.041-22.542-6.496-44.242-2.395-61.086 4.11-16.884 12.718-28.643 24.603-31.828 11.886-3.185 25.22 2.695 37.222 15.262 11.973 12.537 22.429 31.557 28.469 54.1z"
        fill="url(#back-to-the-future-dark_svg__paint5_radial_5760_225999)"
        stroke="#09F"
        strokeWidth={2}
      />
      <path
        d="M157.669 105.459l.873-14.572 19.04-1.544s.032 2.065.383 5.663c.352 3.597.765 5.623.765 5.623l-21.061 4.83z"
        fill="url(#back-to-the-future-dark_svg__paint6_linear_5760_225999)"
      />
      <path
        d="M156.244 86.823l-5.369-3.094 18.433 1.253 2.506 1.841h-15.57z"
        fill="url(#back-to-the-future-dark_svg__paint7_linear_5760_225999)"
      />
      <path
        d="M155.675 102.175l5.8-3.363 9.728-1.398-2.364 2.252-13.164 2.509z"
        fill="url(#back-to-the-future-dark_svg__paint8_linear_5760_225999)"
      />
      <path
        d="M124.83 156.373l5.498-6.03 10.779-5.19-1.919 3.481-14.358 7.739z"
        fill="url(#back-to-the-future-dark_svg__paint9_linear_5760_225999)"
      />
      <path
        d="M106.17 114.142l6.309-5.177 21.452-3.667-8.57 3.496-19.191 5.348z"
        fill="url(#back-to-the-future-dark_svg__paint10_linear_5760_225999)"
      />
      <path
        d="M96.848 81.086l6.442 5.01 21.54 3.105-8.657-3.27-19.325-4.845z"
        fill="url(#back-to-the-future-dark_svg__paint11_linear_5760_225999)"
      />
      <path
        d="M.35 88.145l12.997-2.992 48.442 5.18-18.731.169L.35 88.145z"
        fill="url(#back-to-the-future-dark_svg__paint12_linear_5760_225999)"
      />
      <path
        d="M17.628 71.444l7.19 5.827 27.646 5.88-10.578-4.38-24.258-7.327z"
        fill="url(#back-to-the-future-dark_svg__paint13_linear_5760_225999)"
      />
      <path
        d="M45.435 205.444l8.936-2.41 20.967-18.952-9.748 6.002-20.155 15.36z"
        fill="url(#back-to-the-future-dark_svg__paint14_linear_5760_225999)"
      />
      <path
        d="M24.27 196.33l10.44-8.299 45.947-16.198-16.831 8.223L24.27 196.33z"
        fill="url(#back-to-the-future-dark_svg__paint15_linear_5760_225999)"
      />
      <path
        d="M5.396 143.288l11.265-7.139 47.413-11.202-17.612 6.38-41.066 11.961z"
        fill="url(#back-to-the-future-dark_svg__paint16_linear_5760_225999)"
      />
      <path
        d="M123.105 172.544l7.9-2.049 15.6-15.174-7.873 4.865-15.627 12.358z"
        fill="url(#back-to-the-future-dark_svg__paint17_linear_5760_225999)"
      />
      <path
        d="M163.701 121.094l6.594 1.215 9.631-1.958-2.985-1.325-13.24 2.068z"
        fill="url(#back-to-the-future-dark_svg__paint18_linear_5760_225999)"
      />
      <path
        d="M170.024 141.174l-6.264-15.211 20.041-4.988s.899 2.541 1.718 4.541c.819 2 1.94 4.452 1.94 4.452l-17.435 11.206z"
        fill="url(#back-to-the-future-dark_svg__paint19_linear_5760_225999)"
      />
      <path
        d="M183.878 130.8l-4.164.711-16.963 9.133h3.778l17.349-9.844z"
        fill="url(#back-to-the-future-dark_svg__paint20_linear_5760_225999)"
      />
      <path
        opacity={0.7}
        d="M244.529 95.703c4.38 16.345 4.743 32.031 1.859 44.151-2.899 12.187-8.96 20.386-17.11 22.57-8.15 2.184-17.498-1.887-26.102-10.991-8.558-9.055-16.086-22.82-20.466-39.165-4.38-16.345-4.743-32.03-1.859-44.151 2.9-12.187 8.96-20.386 17.11-22.57 8.15-2.184 17.498 1.887 26.103 10.991 8.557 9.055 16.086 22.82 20.465 39.165z"
        fill="url(#back-to-the-future-dark_svg__paint21_radial_5760_225999)"
        stroke="#09F"
        strokeWidth={3}
      />
      <path
        d="M201.605 96.287v-7.11l19.706-1.158.515 3.739-20.221 4.529z"
        fill="url(#back-to-the-future-dark_svg__paint22_linear_5760_225999)"
      />
      <path
        d="M202.469 105.67l2.754-4.323 13.422-3.221-4.832 3.221-11.344 4.323z"
        fill="url(#back-to-the-future-dark_svg__paint23_linear_5760_225999)"
      />
      <path
        d="M209.938 117.725l-2.852-6.072 17.834-6.619 1.026 3.215-16.008 9.476z"
        fill="url(#back-to-the-future-dark_svg__paint24_linear_5760_225999)"
      />
      <path
        d="M267.47 89.556c3.085 11.515 3.36 22.524 1.39 30.985-1.989 8.542-6.122 14.024-11.464 15.455-5.341 1.431-11.662-1.249-17.655-7.653-5.936-6.342-11.203-16.014-14.289-27.528-3.085-11.515-3.359-22.524-1.39-30.984 1.989-8.543 6.123-14.025 11.464-15.456 5.341-1.431 11.662 1.25 17.656 7.653 5.936 6.342 11.203 16.014 14.288 27.528z"
        fill="url(#back-to-the-future-dark_svg__paint25_radial_5760_225999)"
        stroke="#09F"
        strokeWidth={3.5}
      />
      <path
        opacity={0.75}
        d="M234.46 86.644l15.826-1.194 4.839 10.001-14.408 7.113 2.795-5.532-5.078-.553 2.382-3.335-3.446-1.206 3.484-2.38-6.394-2.914z"
        fill="url(#back-to-the-future-dark_svg__paint26_linear_5760_225999)"
      />
      <path
        d="M296.033 79.49l40.284-10.794-18.517.197-13.654-6.585-27.647 7.408-25.908 18.445 23.415-3.294 22.027-5.377z"
        fill="#ABBCCF"
        stroke="#09F"
        strokeLinejoin="bevel"
      />
      <path
        d="M291.201 69.615l17.966-4.814 9.337 4.286-21.301 5.708-6.002-5.18z"
        fill="#677486"
        stroke="#09F"
      />
      <path
        d="M301.636 78.13l-27.663 6.614-23.641 3.267 2.354 8.787 59.301-15.01 2.708-3.793 22.079-5.916-.842-3.14-16.997 4.555-17.299 4.635z"
        fill="#899CB3"
        stroke="#09F"
      />
      <path
        d="M258.523 98.805l-5.948-1.94 86.839-22.638.725 2.709-81.616 21.869z"
        fill="#3D4A5A"
        stroke="#09F"
        strokeLinejoin="bevel"
      />
      <path
        d="M286.008 71.157l-7.15 1.915-3.416 7.773 16.724-4.481-6.158-5.207z"
        fill="#677486"
        stroke="#09F"
      />
      <path
        d="M311.06 92.673a7.31 7.31 0 005.169-8.953l-9.65 2.586 1.892 7.06 2.589-.693zM274.263 102.532a7.308 7.308 0 005.168-8.952l-9.649 2.586 1.892 7.06 2.589-.694z"
        fill="#445877"
        stroke="#09F"
      />
      <path
        d="M312.37 81.551l.767 2.863 27.122-7.268-1.499-5.594-2.281.612"
        stroke="#09F"
      />
      <circle
        cx={305.599}
        cy={86.568}
        r={7.323}
        transform="rotate(-15 305.599 86.568)"
        fill="#1F2631"
        stroke="#09F"
      />
      <circle
        cx={269.128}
        cy={96.34}
        r={7.323}
        transform="rotate(-15 269.128 96.34)"
        fill="#1F2631"
        stroke="#09F"
      />
      <path d="M328.495 80.434a6.35 6.35 0 01-12.266 3.287" stroke="#09F" />
      <path
        d="M289.987 97.325a6.35 6.35 0 004.49-7.776l-8.382 2.246 1.643 6.133 2.249-.603z"
        fill="#445877"
        stroke="#09F"
      />
      <path
        d="M287.207 98.07a6.348 6.348 0 004.49-7.776l-12.266 3.286a6.35 6.35 0 007.776 4.49z"
        fill="#1F2631"
        stroke="#09F"
      />
      <path
        d="M327.072 87.388a6.349 6.349 0 004.489-7.776l-8.381 2.246 1.643 6.133 2.249-.603z"
        fill="#445877"
        stroke="#09F"
      />
      <path
        d="M324.005 88.21a6.35 6.35 0 004.49-7.776l-12.266 3.287a6.349 6.349 0 007.776 4.49z"
        fill="#1F2631"
        stroke="#09F"
      />
      <path stroke="#09F" d="M298.655 88.947l-22.324 5.982" />
      <path
        d="M247.845 83.638l3.304 4.154 21.359-15.145-1.037-.598-7.155 1.917-1.518 4.62-6.25-2.538-7.564 2.026-1.139 5.564z"
        fill="#404958"
        stroke="#09F"
      />
      <path
        d="M248.984 78.074l9.262 4.31M264.52 73.911l3.52 1.686"
        stroke="#09F"
      />
      <path
        d="M313.818 74.725l.767 2.862 5.52-1.48-.767-2.861-5.52 1.479zM331.4 70.014l.767 2.862 4.293-1.15-.767-2.862-4.293 1.15z"
        fill="#fff"
        stroke="#09F"
      />
      <path
        d="M312.29 81.252l27.122-7.268M252.057 96.742l10.227-2.74M274.956 91.352l23.552-6.311"
        stroke="#09F"
      />
      <path
        d="M276.359 67.4l-.188.096-.433.356-.13.163-8.098 16.226-8.677 2.944-.147.08-.53.413-.158.212-4.275 10.927.276.645 1.254.512.654-.281 4.045-10.352 8.622-2.93.133-.07.511-.37.153-.182 8.111-16.253L299.3 63.69l.354-.613-.351-1.308-.612-.353-22.332 5.984z"
        fill="#DFE9F0"
        stroke="#09F"
        strokeLinejoin="bevel"
      />
      <defs>
        <linearGradient
          id="back-to-the-future-dark_svg__paint0_linear_5760_225999"
          x1={78.671}
          y1={160.56}
          x2={37.008}
          y2={178.759}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFAFA" stopOpacity={0.75} />
          <stop offset={1} stopColor="#FAFAFA" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint1_linear_5760_225999"
          x1={66.284}
          y1={109.986}
          x2={21.317}
          y2={117.375}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFAFA" stopOpacity={0.75} />
          <stop offset={1} stopColor="#FAFAFA" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint3_linear_5760_225999"
          x1={135.153}
          y1={100.657}
          x2={114.79}
          y2={103.415}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFAFA" stopOpacity={0.75} />
          <stop offset={1} stopColor="#FAFAFA" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint4_linear_5760_225999"
          x1={144.639}
          y1={139.727}
          x2={129.15}
          y2={144.954}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFAFA" stopOpacity={0.75} />
          <stop offset={1} stopColor="#FAFAFA" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint6_linear_5760_225999"
          x1={178.864}
          y1={95.007}
          x2={162.496}
          y2={97.727}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFAFA" stopOpacity={0.75} />
          <stop offset={1} stopColor="#FAFAFA" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint7_linear_5760_225999"
          x1={172.487}
          y1={82.95}
          x2={168.84}
          y2={92.875}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E1E1" stopOpacity={0.79} />
          <stop offset={1} stopColor="#E1E1E1" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint8_linear_5760_225999"
          x1={171.985}
          y1={96.747}
          x2={156.77}
          y2={97.47}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E1E1" stopOpacity={0.79} />
          <stop offset={1} stopColor="#E1E1E1" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint9_linear_5760_225999"
          x1={141.77}
          y1={144.092}
          x2={124.375}
          y2={150.511}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E1E1" stopOpacity={0.79} />
          <stop offset={1} stopColor="#E1E1E1" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint10_linear_5760_225999"
          x1={135.137}
          y1={104.212}
          x2={108.634}
          y2={113.793}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E1E1" stopOpacity={0.79} />
          <stop offset={1} stopColor="#E1E1E1" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint11_linear_5760_225999"
          x1={126.065}
          y1={90.255}
          x2={99.32}
          y2={81.37}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E1E1" stopOpacity={0.79} />
          <stop offset={1} stopColor="#E1E1E1" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint12_linear_5760_225999"
          x1={59.566}
          y1={89.239}
          x2={4.744}
          y2={89.239}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1D1D1" />
          <stop offset={1} stopColor="#D1D1D1" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint13_linear_5760_225999"
          x1={51.15}
          y1={83.712}
          x2={19.598}
          y2={82.022}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1D1D1" />
          <stop offset={1} stopColor="#D1D1D1" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint14_linear_5760_225999"
          x1={75.023}
          y1={185.474}
          x2={55.16}
          y2={210.048}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1D1D1" />
          <stop offset={1} stopColor="#D1D1D1" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint15_linear_5760_225999"
          x1={78.179}
          y1={171.803}
          x2={28.708}
          y2={195.425}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1D1D1" />
          <stop offset={1} stopColor="#D1D1D1" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint16_linear_5760_225999"
          x1={61.613}
          y1={124.653}
          x2={9.904}
          y2={142.861}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1D1D1" />
          <stop offset={1} stopColor="#D1D1D1" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint17_linear_5760_225999"
          x1={148.193}
          y1={154.985}
          x2={124.838}
          y2={170.758}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E1E1" stopOpacity={0.79} />
          <stop offset={1} stopColor="#E1E1E1" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint18_linear_5760_225999"
          x1={180.887}
          y1={120.716}
          x2={166.315}
          y2={125.156}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E1E1" stopOpacity={0.79} />
          <stop offset={1} stopColor="#E1E1E1" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint19_linear_5760_225999"
          x1={187.308}
          y1={125.323}
          x2={170.586}
          y2={133.001}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFAFA" stopOpacity={0.75} />
          <stop offset={1} stopColor="#FAFAFA" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint20_linear_5760_225999"
          x1={161.151}
          y1={140.739}
          x2={173.939}
          y2={124.989}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E1E1" stopOpacity={0.79} />
          <stop offset={1} stopColor="#E1E1E1" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint22_linear_5760_225999"
          x1={224.847}
          y1={90.763}
          x2={207.659}
          y2={96.644}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFAFA" stopOpacity={0.75} />
          <stop offset={1} stopColor="#FAFAFA" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint23_linear_5760_225999"
          x1={219.224}
          y1={97.486}
          x2={207.966}
          y2={109.067}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E1E1" stopOpacity={0.79} />
          <stop offset={1} stopColor="#E1E1E1" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint24_linear_5760_225999"
          x1={226.082}
          y1={107.937}
          x2={213.084}
          y2={115.307}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFAFA" stopOpacity={0.75} />
          <stop offset={1} stopColor="#FAFAFA" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="back-to-the-future-dark_svg__paint26_linear_5760_225999"
          x1={250.41}
          y1={92.502}
          x2={238.581}
          y2={96.749}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFAFA" />
          <stop offset={1} stopColor="#FAFAFA" stopOpacity={0} />
        </linearGradient>
        <radialGradient
          id="back-to-the-future-dark_svg__paint2_radial_5760_225999"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(29.24655 109.14962 -59.40444 15.91737 131.516 125.985)"
        >
          <stop stopColor="#D9D9D9" stopOpacity={0} />
          <stop offset={1} stopColor="#D9D9D9" />
        </radialGradient>
        <radialGradient
          id="back-to-the-future-dark_svg__paint5_radial_5760_225999"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(75 18.23 176.195) scale(85 46.5)"
        >
          <stop stopColor="#D9D9D9" stopOpacity={0} />
          <stop offset={1} stopColor="#D9D9D9" />
        </radialGradient>
        <radialGradient
          id="back-to-the-future-dark_svg__paint21_radial_5760_225999"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(75 39.051 191.19) scale(62 33.5)"
        >
          <stop stopColor="#D9D9D9" stopOpacity={0} />
          <stop offset={1} stopColor="#D9D9D9" />
        </radialGradient>
        <radialGradient
          id="back-to-the-future-dark_svg__paint25_radial_5760_225999"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(11.38804 42.50074 -22.69926 6.08225 246.461 95.186)"
        >
          <stop stopColor="#D9D9D9" stopOpacity={0} />
          <stop offset={1} stopColor="#D9D9D9" />
        </radialGradient>
      </defs>
    </Svg>
  );
}

const ForwardRef = React.forwardRef(SvgBackToTheFutureDark);
export default ForwardRef;
